// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-shops-js": () => import("./../src/templates/shops.js" /* webpackChunkName: "component---src-templates-shops-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-products-js": () => import("./../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

